@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);
.navbar-brand{font-weight:600;letter-spacing:1px;text-transform:uppercase;cursor:pointer}.navbar-brand:hover{color:#e75926 !important}.navbar-nav .nav-item{letter-spacing:1px;margin-left:20px;text-align:center;text-transform:uppercase;cursor:pointer}.navbar-nav .nav-item a{color:#fff !important;display:inline;font-size:14px}.navbar-nav .nav-item a:hover{color:#e75926 !important}

.intro{align-items:center;background-size:cover;display:flex;height:100% !important}@media (max-width: 768px){.intro{padding-top:500px;padding-bottom:500px}}.intro>.col{padding:150px 0 150px 0}.intro .row{justify-content:center}.intro .row h1{color:#000;font-size:60px;font-weight:700;text-align:center;text-transform:uppercase}.intro .row h1 .brand{color:#e75926;text-shadow:0 4px #a13e1a}.intro .row p{color:#000;font-size:20px;font-weight:700;text-align:center}.intro .row svg{color:#000;font-size:4em}.intro .social{margin-top:30px}.intro .social>*{display:flex;justify-content:center}

.about-parallax{height:auto !important;background-size:cover}.about{background-size:cover;display:flex}.about>.col{padding:150px 0 150px 0}.about .col>.row:not(:last-child){margin-bottom:40px}.about .row{justify-content:center;align-items:center}.about .row .col-sm-2{text-align:center}.about .row h2{color:#fff;font-size:40px;font-weight:700;text-transform:uppercase}.about .row p{color:#fff;font-size:20px;text-align:center}.about .row a,.about .row a:not([href]){color:#fff;cursor:pointer;text-decoration:underline}.about .row img{max-width:100%}.about .row svg{fill:#fff;padding-left:10px}

.education-parallax{height:auto !important;background-size:cover}.education{background-size:cover;display:flex}.education>.col{padding:150px 0 150px 0}.education .col>.row:not(:last-child){margin-bottom:40px}.education .row{justify-content:center;align-items:center}.education .row h2{color:#fff;font-size:40px;font-weight:700;text-transform:uppercase}.education .row h3{color:#fff;font-size:30px;font-weight:700;text-align:center}.education .row p{color:#fff;font-size:16px;font-weight:700;text-align:center}

.experience-parallax{height:auto !important;background-size:cover}.experience{background-size:cover;display:flex}.experience>.col{padding:150px 0 150px 0}.experience .col>.row:not(:last-child){margin-bottom:40px}.experience .row{justify-content:center;align-items:center}.experience .row h2{color:#fff;font-size:40px;font-weight:700;text-transform:uppercase}.experience .row h3{color:#fff;font-size:30px;font-weight:700;text-align:center}.experience .row h4{color:#fff;font-size:16px;font-weight:700;text-align:center}.experience .row p{color:#fff;font-size:16px;font-weight:700;text-align:center}

.open-source-parallax{height:auto !important;background-size:cover}.open-source{background-size:cover;display:flex}.open-source>.col{padding:150px 0 150px 0}.open-source .col>.row:not(:last-child){margin-bottom:40px}.open-source .row{justify-content:center;align-items:center}.open-source .row h2{color:#fff;font-size:40px;font-weight:700;text-transform:uppercase}.open-source .row h3{color:#fff;font-size:30px;font-weight:700;text-align:center}.open-source .row p{color:#fff;font-size:16px;font-weight:700;margin-bottom:0;text-align:center}.open-source .row img{width:100%}

.contact-parallax{height:auto !important;background-size:cover}.contact{background-size:cover;display:flex}.contact>.col{padding:150px 0 150px 0}.contact .col>.row:not(:last-child){margin-bottom:40px}.contact .row{align-items:center;justify-content:center}.contact .row h2{color:#fff;font-size:40px;font-weight:700;text-align:center;text-transform:uppercase}.contact .row svg{color:#fff;font-size:4em}.contact .row p{margin-bottom:0;text-align:center}.contact .social{margin-top:30px}.contact .social>*{display:flex;justify-content:center}.contact footer{color:#fff;font-size:20px;margin-top:100px}

html,body,.App,#root{width:100%;height:100%;margin:0;font-family:'Open Sans', sans-serif;-webkit-font-smoothing:antialiased !important}

