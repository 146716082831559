@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');

html,
body,
.App,
#root {
  width: 100%;
  height: 100%;
  margin: 0;

  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased !important;
}
